<template>
    <div v-show="loadingState" class="developing">
        <div class="zhuan1"></div>
        <div class="zhuan2"></div>
        <div class="text">
            <div>{{ text }}...</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'visualizationLoading',
    props: {
        loadingState: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: '正在加载'
        }
    }
};
</script>
<style lang="scss" scoped>
@keyframes rotat {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.developing {
    position: absolute;
    width: 20vw !important;
    height: 20vw !important;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #02195d70;

    .zhuan1 {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../../../assets/visualization/IntelligentSecurity/a2.png');
        background-size: 100% 100%;
        animation: rotat 7s linear infinite;
    }

    .zhuan2 {
        position: absolute;
        width: 70%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('../../../assets/visualization/IntelligentSecurity/a1.png');
        background-size: 100% 100%;
        animation: rotat 7s linear infinite reverse;
    }

    .text {
        color: #02abe7;
        font-weight: bolder;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 2.1vh;
    }
}
</style>