import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=1e482f28&scoped=true"
import script from "./loading.vue?vue&type=script&lang=js"
export * from "./loading.vue?vue&type=script&lang=js"
import style0 from "./loading.vue?vue&type=style&index=0&id=1e482f28&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e482f28",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1e482f28')) {
      api.createRecord('1e482f28', component.options)
    } else {
      api.reload('1e482f28', component.options)
    }
    module.hot.accept("./loading.vue?vue&type=template&id=1e482f28&scoped=true", function () {
      api.rerender('1e482f28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/visualization/components/loading.vue"
export default component.exports